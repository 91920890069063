import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import './nav-item.scss';
import { AppContext } from '../../shared/context/AppContext';
export interface NavItemProps {
  name: string;
  link: string;
  exact: boolean;
}
const NavItem = (props: NavItemProps) => {
  const appContext = useContext(AppContext);
  const closeSubMenu = () => {
    appContext.setIsMenuOpened(false);
  }
  const navItemClassName = appContext.isHeaderTransparent && !appContext.isHeaderFixed
    ? 'nav-item nav-item_is-transparent'
    : 'nav-item';

  return (
    <NavLink
      exact={props.exact}
      className={navItemClassName}
      activeClassName="nav-item_is-active"
      onClick={closeSubMenu}
      to={props.link}
    >
      {props.name}
    </NavLink>
  );
};

export default NavItem;
