export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const PLACEHOLDER_IMAGE = 'https://placehold.it/74';

export enum DisplayStyle {
  GRID,
  LIST
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export enum ProductSortBy {
  NAME = 'name',
  PRICE = 'retail_price'
}

export interface PageLink {
  name: string;
  link: string;
  exact: boolean;
}

export interface Paginator {
  currentPage: number;
  lastPage: number;
  perPage: number;
  total: number;
}

export enum Pages {
  HOME = 'Home',
  WORK_WITH_US = 'Why Us',
  ABOUT_US = 'About Us',
  PARTNERS = 'Partners',
  CONTACT_US = 'Contact Us'
}

export interface AppContextInterface {
  isHeaderTransparent: boolean,
  setIsHeaderTransparent: Function,
  isHeaderFixed: boolean,
  setIsHeaderFixed: Function,
  isMenuOpened: boolean,
  setIsMenuOpened: Function
}

export enum ErrorMessages {
  FIELD_REQUIRED = 'This field is required',
  EMAIL_FORMAT = 'Please enter a valid email address',
  MOBILE_FORMAT = 'Please enter a valid mobile number'
}
