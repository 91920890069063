import React, { useEffect, useContext } from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import AppRouter from '../../AppRouter/AppRouter';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../shared/context/AppContext';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
let AppContent = () => {
  let location = useLocation();
  const appContext = useContext(AppContext);
  const moveToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y * -1 > 70) {
      appContext.setIsHeaderFixed(true);
      if (location.pathname === '/') {
        appContext.setIsHeaderTransparent(false);
      }
    } else {
      appContext.setIsHeaderFixed(false);
      if (location.pathname === '/') {
        appContext.setIsHeaderTransparent(true);
      }
    }
  });
  useEffect(() => {
    moveToTop();
    if (location.pathname === '/') {
      appContext.setIsHeaderTransparent(true);
    } else {
      appContext.setIsHeaderTransparent(false);
    }
  }, [location]);
  let headerClassName = 'app__header';
  let navigationClassName = 'app__navigation app__navigation_is-hidden';
  if (appContext.isHeaderFixed) {
    headerClassName = `${headerClassName} app__header_is-fixed`;
    navigationClassName = 'app__navigation';
  }
  if (appContext.isHeaderTransparent) {
    headerClassName = `${headerClassName} app__header_is-transparent`;
  }
  return (
    <div className="app__container">
      <div className={headerClassName}>
        <Header />
      </div>
      <div className="app__body">
        <AppRouter />
        <div className={navigationClassName} onClick={moveToTop}>
          <div className="app__navigation-item"></div>
        </div>
      </div>
      <div className="app__footer">
        <Footer />
      </div>
    </div>
  );
};

export default AppContent;
