import React from 'react';
import './banners.scss';
import Banner, { BannerImage } from '../Banner/Banner';
import banner1 from '../../assets/images/banners/1.jpg';
import banner2 from '../../assets/images/banners/2.jpg';
import banner3 from '../../assets/images/banners/3.jpg';
import banner4 from '../../assets/images/banners/4.jpg';
import banner5 from '../../assets/images/banners/5.jpg';
import banner6 from '../../assets/images/banners/6.jpg';
import banner7 from '../../assets/images/banners/7.jpg';
import banner8 from '../../assets/images/banners/8.jpg';

let Banners = () => {
  const banners: BannerImage[] = [
    { name: 'banner1', link: '', source: banner1 },
    { name: 'banner1', link: '', source: banner2 },
    { name: 'banner3', link: '', source: banner3 },
    { name: 'banner', link: '', source: banner4 },
    { name: 'banner', link: '', source: banner5 },
    { name: 'banner', link: '', source: banner6 },
    { name: 'banner', link: '', source: banner7 },
    { name: 'banner', link: '', source: banner8 }
  ];
  const bannerImages: BannerImage[] = [];
  banners.map((banner: BannerImage) => {
    const image = banner.source;
    const bannerProp: BannerImage = {
      source: image,
      name: banner.name,
      link: banner.link
    };
    bannerImages.push(bannerProp);
  });
  return (
    <div className="banners__item">
      <Banner banners={bannerImages} />
    </div>
  );
};

export default Banners;
