import React from 'react';
import './category-item.scss';
import { Category } from '../../types/category';

export interface CategoryItemProps {
  category: Category;
  isMask: boolean;
}

const CategoryItem = (props: Partial<CategoryItemProps>) => {
  let categoryItemClassName = 'category-item';
  if (props.category && props.category.isSelected) {
    categoryItemClassName = 'category-item category-item_is-selected';
  }

  if (props.isMask || !props.category) {
    return (
      <div className="category-item category-item_is-mask mask">
        <div className="category-item__link">
          <div className="category-item__image-container"></div>
          <div className="category-item__name-container">
            <div className="category-item__mask"></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={categoryItemClassName}>
        <div className="category-item__name-container">
          <div className="category-item__name">{props.category.name}</div>
        </div>
      </div>
    );
  }
};

export default CategoryItem;
