import React from 'react';
import './home.scss';
import Categories from '../../Categories/Categories';
import Banners from '../../Banners/Banners';
import { Link } from 'react-router-dom';

let Home = () => {
  return (
    <div className="home">
      <div className="home__banner">
        <Banners />
      </div>
      <div className="home__container">
        <div className="home__categories-container">
          <div className="home__categories-title">Our Services</div>
          <div className="home__categories">
            <Categories />
          </div>
        </div>

        <div className="home__section-container">
          <div className="home__section">
            <div className="home__section-title">About Us</div>
            <div className="home__section-content">
              Elinor GmbH Travel is one of the leading B2B Destination
              Management Companies (DMC) in Germany, providing comprehensive
              travel services for groups and individuals. Elinor GmbH Travel was
              first established in the year 2015 in Düsseldorf, Germany by
              qualified professionals with more than twenty years of experience
              in the tourism industry
            </div>
            <div className="home__section-button-container">
              <Link to="about" className="home__section-button">
                Read More
              </Link>
            </div>
          </div>
          <div className="home__section">
            <div className="home__section-title">Why Us</div>
            <div className="home__section-content">
              Elinor GmbH Travel is an insured and fully licensed travel agency
              approved by the local authorities. And as members of IATA - Travel Industry Designator Service, we are held up to a high level of
              standards and practices.
            </div>
            <div className="home__section-button-container">
              <Link to="why-us" className="home__section-button">
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
