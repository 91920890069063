import React from 'react';
import './partners.scss';
import './partner-item.scss';
import partnersBanner from '../../../assets/images/pages/partner.jpg';
import partner1 from '../../../assets/images/partners/1.png';
import partner2 from '../../../assets/images/partners/2.jpg';
import partner3 from '../../../assets/images/partners/3.png';
import partner4 from '../../../assets/images/partners/4.png';
import partner5 from '../../../assets/images/partners/5.png';
import partner6 from '../../../assets/images/partners/6.png';
import partner7 from '../../../assets/images/partners/7.png';
import partner8 from '../../../assets/images/partners/8.jpg';
import partner9 from '../../../assets/images/partners/9.jpg';
import partner11 from '../../../assets/images/partners/11.jpg';
import partner12 from '../../../assets/images/partners/12.jpg';
import partner13 from '../../../assets/images/partners/13.png';
import partner14 from '../../../assets/images/partners/14.jpg';
import partner15 from '../../../assets/images/partners/15.jpg';
import PageBanner from '../../PageBanner/PageBanner';
import { Partner } from '../../../types/partner';

const partners: Partner[] = [
  {
    name: '',
    image: partner8
  },
  {
    name: '',
    image: partner2
  },
  {
    name: '',
    image: partner3,
    invertImage: true
  },
  {
    name: '',
    image: partner4
  },
  {
    name: '',
    image: partner5,
    invertImage: true
  },
  {
    name: '',
    image: partner6
  },
  {
    name: '',
    image: partner7
  },
  {
    name: '',
    image: partner1, 
    invertImage: true
  },
  {
    name: '',
    image: partner9,
    invertImage: true,
  },
  {
    name: '',
    image: partner11,
    invertImage: true,
  },
  {
    name: '',
    image: partner12,
    invertImage: true,
  },
  {
    name: '',
    image: partner13,
  },
  {
    name: '',
    image: partner14,
    invertImage: true,
  },
  {
    name: '',
    image: partner15,
  }
];

const Partners = () => {

  const getClassName = (isInverted: boolean | undefined) => {
    const className = 'partner-item__image';
    return isInverted ? `${className} ${className}_is-inverted` : className;
  }
  const partnerItems = partners.map((partner, index) => (
    <div key={index} className="partners__partner-item">
    <div className="partner-item">
        <div className="partner-item__image-container">
          <img className={getClassName(partner.invertImage)} src={partner.image} alt={partner.name} />
        </div>
        <div className="partner-item__name-container">
          <div className="partner-item__name">
            {partner.name}
          </div>
        </div>
    </div>
   
    </div>
  ));
  return (
    <div className="partners">
      <div className="partners__banner">
        <PageBanner image={partnersBanner} title="Some of our Partners"></PageBanner>
      </div>
      <div className="partners__container">{partnerItems}</div>
    </div>
  );
};

export default Partners;
