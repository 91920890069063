import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '600px'
};

const center = {
    lat: 51.210708,
    lng: 6.796104
};

let  MyMapComponent = () => {
    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return (
        <LoadScript
            googleMapsApiKey="AIzaSyDp17b3EX0QiSFrxr8GnJ5ndZp-0G37vMs"
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={11}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                <Marker position={center}></Marker>
            </GoogleMap>
        </LoadScript>
    )
}

export default MyMapComponent;