import React from 'react';
import './about.scss';
import about from '../../../assets/images/pages/about.jpg';
import PageBanner from '../../PageBanner/PageBanner';

const About = () => {
  return (
    <div className="about">
      <div className="about__banner">
        <PageBanner image={about} title="about us"></PageBanner>
      </div>
      <div className="about__container">
        <div className="about__section">
          <div className="about__description">
            <div className="about__item">
              Elinor GmbH Travel is one of the leading B2B Destination
              Management Companies(DMC) in Germany, providing comprehensive
              travel services for groups and individuals. Elinor GmbH Travel was
              first established in the year 2015 in Düsseldorf, Germany by
              qualified professionals with more than twenty years of experience
              in the tourism industry. Our intimate, first-hand knowledge of
              German and European markets enables us to offer our clients the
              very best and allows us to be one of the leading travel agencies
              connecting Middle East market to German and European markets. Our
              philosophy of working with you at every stage of the planning
              process and our extensive and diversified portfolio of tours, five
              & four stars hotels selection, and services and unmatched travel
              packages allows us to support our travel professional partners not
              only with attending their requests and queries but also with
              providing them alternatives and travel program solutions.
              Moreover, our Hotel Bank counts many contracted rooms all over
              Germany which allows us to manage hotel bookings and other related
              travel services in the most effective way, securing most
              competitive rates and availability as well as showing full
              flexibility towards our clients.
            </div>
            <div className="about__item">
              Our services include hotel reservations, private jets, ground
              services, VIP services, limousine services, activity, leisure
              programs, medical tourism.
            </div>
            <div className="about__item"></div>
            <div className="about__item"></div>
            <div className="about__item"></div>
            <div className="about__item"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
