import React from "react";
import "./footer.scss";
import { NavLink } from "react-router-dom";

const year = new Date().getFullYear();

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__top">
          <div className="footer__top-container">
            <div className="footer__top-section"></div>
            <div className="footer__top-section">
              <div className="footer__item-title">Customer Support</div>
              <div className="footer__item">+49(0) 211 93071112</div>
              <div className="footer__item">+49(0) 152 53994914</div>
              <div className="footer__item-title">
                <a
                  className="footer__item-link"
                  target="_blank"
                  href="mailto:incoming@elinortravel.com"
                >
                  incoming@elinortravel.com
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="footer__bottom-container">
            <div className="footer__copyright">
              © {year}
              <NavLink to="copyright" className="footer__copyright-link">
                <u>Elinor GmbH</u>
              </NavLink>
              All Rights Reserved
            </div>
            <div className="footer__copyright footer__copyright_is-small">
              Designed and Developed by
              <a
                className="footer__copyright-link footer__copyright-link_is-secondary"
                href="https://smartwaysdigital.com"
                target="_blank"
              >
                Smartways Ltd.
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
