import React from 'react';
import './copyright.scss';

const Copyright = () => {
  return (
    <div className="copyright">
      <div className="copyright__container">
        <div className="copyright__section">
          <div className="copyright__section-title">Terms and Conditions</div>
          <p dir="ltr">Elinor GmbH</p>
          <p>Kruppstrasse 100</p>
          <p dir="ltr">40227 Düsseldorf</p>
          <p dir="ltr">vertreten durch den Geschäftsführer</p>
          <p dir="ltr">Herrn Anwar Al Banna</p>
          <p className="copyright__item-title">
            Allgemeine Geschäftsbedingungen Elinor
          </p>
          <p className="copyright__item-title">1. Allgemeines</p>
          <p>
            Diese Allgemeinen Geschäftsbedingungen gelten für sämtliche
            Rechtsbeziehungen zwischen der Elinor GmbH (im Folgenden: „Elinor“)
            und dem Kunden/ Reisenden und/ oder Nutzer unter der Internetpräsenz
            www.elinortravel.com sowie seinen Mitreisenden. Diese Reise- und
            Zahlungsbedingungen werden somit Bestandteil de mit Elinor
            geschlossenen Reisevertrages. Sie gelten ausdrücklich nicht für
            Leistungen, die mit dem Zusatz „vermittelt“ gekennzeichnet
            sind.Fürdiese Leistungen gelten die gesonderten Allgemeinen
            Geschäftsbedingungen „Reisevermittlung“
          </p>
          <p className="copyright__item-title">
            2. Abschluss des Reisevertrages
          </p>
          <p dir="ltr">
            Mit dem erfolgreichen Durchlaufen sämtlicher Online-Buchungsschritte
            bietet der Kunde/ Nutzer Elinor den Abschluss eines Reisevertrages
            verbindlich an. Eine Buchung zum Abschluss eines Reisevertrages kann
            schriftlich, fernmündlich, per Fax oder per Internet vorgenommen
            werden. Der Vertrag kommt mit der Annahme durch die Zusendung der
            Reisebestätigung durch Elinor zustande. Die Annahme bedarf keiner
            bestimmten Form. Bei oder spätestens 7 Tage nach Vertragsschluss
            wird Elinor dem Kunden die Reisebestätigung zukommen lassen. Elinor
            bietet keine Buchungsmöglichkeiten für Minderjährige an. Weicht der
            Inhalt der Reisebestätigung vom Inhalt der Buchung ab, so liegt ein
            neues Angebot von Elinor vor, an das Elinor für die Dauer von 10
            Tagen gebunden ist. Der Vertrag kommt auf der Grundlage dieses neuen
            Angebots zustande, wenn der Kunde innerhalb der Bindungsfrist Elinor
            die Annahme erklärt.
          </p>
          <p className="copyright__item-title">3. Bezahlung</p>
          <p>
            Innerhalb einer Woche nach Erhalt der Reisebestätigung/Rechnung ist
            gegen. Aushändigung des Sicherungsscheins eine Anzahlung in Höhe von
            20% des Gesamtpreisesder Rechnung an Elinor zu entrichten. Die
            Restzahlung ist ohne nochmalige Aufforderung spätestens 6 Wochen vor
            Reisebeginn fällig. Bei Buchungen innerhalb von 30 Tagen vor
            Reisebeginn ist der gesamte Reisepreis bei Vertragsschlussfällig.
          </p>
          <p dir="ltr">
            Wenn der vereinbarte Anzahlungsbetrag auch nach Inverzugsetzung oder
            der Reisepreis bis zum Reiseantritt nicht vollständig bezahlt ist,
            berechtigt dies Elinor zur Auflösung des Reisevertrages und zur
            Berechnung von Schadensersatz in Höhe der entsprechenden
            Rücktrittsgebühren, vorausgesetzt, es läge nicht bereits zu diesem
            Zeitpunkt ein zum Rücktritt berechtigender Reisemangel vor.
          </p>
          <p className="copyright__item-title">4. Leistungen</p>
          <p dir="ltr">
            Welche Leistungen vertraglich vereinbart sind, ergibt sich aus den
            Leistungsbeschreibungen der Internetseite www.elinortravel.com und
            aus den hierauf bezugnehmenden Angaben in der Reisebestätigung.
            Elinor bemüht sich, Bilder und Leistungsbeschreibungen auf ihrer
            Internetseite stets fehlerfrei zu halten. Angesichts der großen
            Anzahl der Ferienobjekte kann es jedoch zu unbeabsichtigten
            fehlerhaften Angaben oder Darstellungen auf der Internetpräsenz
            kommen. Elinor behält sich daher das Recht vor, solche
            Unrichtigkeiten zu korrigieren. Änderungen der Internetpräsenz
            bleiben vorbehalten. Die auf der Internetseite www.elinortravel.com
            enthaltenen Angaben sind für Elinor bindend. Elinor behält sich
            jedoch ausdrücklich vor, aus sachlich berechtigten, erheblichen und
            nicht vorhersehbaren Gründen vor Vertragsschluss eine Änderung der
            Prospektangaben zu erklären, über die der Kunde vor der Buchung
            selbstverständlich informiert wird. Abweichungen wesentlicher
            Reiseleistungen von dem vereinbarten Inhalt des Reisevertrages, die
            nach Vertragsabschluss notwendig werden und von Elinor nicht wider
            Treu und Glauben herbeigeführt wurden, sind nur gestattet, soweit
            die Abweichungen nicht erheblich sind und den Gesamtzuschnitt der
            Reise nicht beeinträchtigen.
          </p>
          <p className="copyright__item-title">
            5. Rücktritt durch den Kunden, Umbuchungen
          </p>
          <p dir="ltr">
            5.1 Rücktritte Der Kunde kann jederzeit vor Reisebeginn von der
            Reise zurücktreten. Maßgeblich ist der Zugang der
            Rücktrittserklärung bei Elinor. Dem Kunden wird empfohlen, den
            Rücktritt, schriftlich unter Nennung der Buchungsnummer zu erklären.
            Tritt der Kunde vor Reisebeginn zurück oder tritt er die Reise nicht
            an, so verliert Elinor den Anspruch auf den Reisepreis. Stattdessen
            kann der Elinor, soweit der Rücktritt nicht von ihr zu vertreten ist
            oder ein Fall höherer Gewalt vorliegt, eine angemessene
            Entschädigung für die bis zum Rücktritt getroffenen
            Reisevorkehrungen und seine Aufwendungen in Abhängigkeit von dem
            jeweiligen Reisepreis verlangen. Elinor hat diesen
            Entschädigungsanspruch zeitlich gestaffelt, d.h. unter
            Berücksichtigung der Nähe des Zeitpunktes des Rücktritts zum
            vertraglich vereinbarten Reisebeginn in einem prozentualen
            Verhältnis zum Reisepreis pauschaliert und bei der Berechnung der
            Entschädigung gewöhnlich ersparte Aufwendungen und gewöhnlich
            mögliche anderweitige Aufwendungen der Reiseleistungen
            berücksichtigt. Die Entschädigung wird nach dem Zeitpunkt des
            Zugangs der Rücktrittserklärung wie folgt berechnet: a) Kreuzfahrten
            Bis 30 Tag vor Reiseantritt 25 % ab 29. bis 22. Tag vor Reiseantritt
            40 % ab 21. bis 15 Tag vor Reiseantritt 60 % ab 14. Tag vor
            Reiseantritt 80 % des Reisepreises. b) Bei Ferienhäusern, Apartments
            oder sonstigen Landleistungen: Bis 61. Tag vor Reiseantritt 20 %ab
            60. bis 35. Tag vor Reiseantritt 50 %ab 34. Tag vor Reiseantritt 80
            % des Reisepreises.
          </p>
          <p dir="ltr">
            Dem Kunden bleibt es in jedem Fall unbenommen, Elinor nachzuweisen,
            dass dieser überhaupt kein oder ein wesentlich niedriger Schaden
            entstanden ist, als die von ihr geforderte Pauschale. Elinor behält
            sich vor, anstelle der vorstehenden Pauschalen eine höhere,
            individuell berechnete Entschädigung zu fordern, soweit Elinor
            nachweist, dass ihr wesentlich höhere Aufwendungen als die jeweils
            anwendbare Pauschale entstanden sind. In diesem Fall ist Elinor
            verpflichtet, die geforderte Entschädigung unter Berücksichtigung
            der ersparten Aufwendungen und einer etwaigen, anderweitigen
            Verwendung der Reiseleistungen konkret zu beziffern und zu belegen.
            Das gesetzliche Recht des Kunden, gemäß § 651 b BGB einen
            Ersatzteilnehmer zu stellen, bleibt durch die vorstehenden
            Bedingungen unberührt.
          </p>
          <p dir="ltr">
            5.2 Umbuchungen Für Änderungen hinsichtlich des Reisetermins oder
            der Unterbringung (Umbuchung), sofern diese möglich sind, berechnet
            Elinor eine Gebühr in Höhe von EUR 30,00. Elinor ist nicht zur
            Erfüllung der Änderungswünsche verpflichtet.
          </p>
          <p className="copyright__item-title">
            6. Außergewöhnliche Umstände, Höhere Gewalt
          </p>
          <p dir="ltr">
            6.1. Außergewöhnliche Umstände Elinor kann vor Reisebeginn vom
            Reisevertrag zurücktreten oder nach Reisebeginn den Reisevertrag
            ohne Einhaltung einer Frist kündigen, wenn der Kunde die
            Durchführung der Reise ungeachtet einer Abmahnung durch Elinor
            nachhaltig stört oder wenn er sich in einem solchen Masse
            vertragswidrig verhält, dass die sofortige Aufhebung des Vertrages
            gerechtfertigt ist. Im Fall der Kündigung behält Elinor den Anspruch
            auf den Reisepreis. Elinor muss sich jedoch den Wert der ersparten
            Aufwendungen sowie diejenigen Vorteile anrechnen lassen, die Elinor
            aus einer anderweitigen Verwendung der nicht in Anspruch genommene
            Leistung erlangt.
          </p>
          <p dir="ltr">
            6.2. Höhere Gewalt Wird die Reise infolge bei Vertragsschluss nicht
            voraussehbar höherer Gewalt erheblich erschwert, gefährdet oder
            beeinträchtigt, so können sowohl Elinor als auch der Kunde den
            Vertrag kündigen. Wird der Vertrag gekündigt, so kann Elinor für die
            bereits erbrachten oder zur Beendigung der Reise noch zu
            erbringenden Reiseleistungen eine angemessene Entschädigung
            verlangen.
          </p>
          <p className="copyright__item-title">
            7. Beschränkung der Haftung von Elinor
          </p>
          <p dir="ltr">
            Die vertragliche Haftung von Elinor für Schäden, die nicht
            Körperschäden sind, ist auf den dreifachen Reisepreis beschränkt. 1.
            soweit ein Schaden des Kunden weder vorsätzlich noch grob fahrlässig
            herbeigeführt wird oder 2. soweit Elinor für einen dem Kunden
            entstehenden Schaden allein wegen eines Verschuldens eines
            Leistungsträgers verantwortlich ist. Elinor haftet nicht für
            Leistungsstörungen, Personen- und Sachschäden im Zusammenhang mit
            Leistungen, die als Fremdleistungen lediglich vermittelt werden
            (z.B. Ausflüge, Sportveranstaltungen, Theaterbesuche, Ausstellungen,
            Beförderungsleistungen von und zum ausgeschriebenen Ausgangs- und
            Zielort), wenn diese Leistungen in der Reiseausschreibung und der
            Buchungsbestätigung ausdrücklich und unter Angabe des vermittelten
            Vertragspartners als Fremdleistungen so eindeutig gekennzeichnet
            werden, dass sie für den Kunden erkennbar nicht Bestandteil der
            Reiseleistungen von Elinor sind.
          </p>
          <p dir="ltr">
            Ein Schadensersatzanspruch gegen Elinor ist insoweit beschränkt oder
            ausgeschlossen, als aufgrund internationaler Übereinkommen oder auf
            solchen beruhenden gesetzlichen Vorschriften, die auf die vom
            Leistungsträger zu erbringenden Leistungen anzuwenden ist, ein
            Anspruch auf Schadensersatz gegen den Leistungsträger nur unter
            bestimmten Voraussetzungen oder Beschränkungen geltend gemacht
            werden kann oder unter bestimmten Voraussetzungen ausgeschlossen
            ist.
          </p>
          <p className="copyright__item-title">8. Gewährleistung</p>
          <p dir="ltr">
            Wird die Reise nicht vertragsgemäß erbracht, so kann der Kunde
            Abhilfe verlangen. Elinor kann die Abhilfe verweigern, wenn sie
            einen unverhältnismäßigen Aufwand erfordert. Elinor kann auch in der
            Weise Abhilfe schaffen, dass eine gleichwertige Ersatzleistung
            erbracht wird.
          </p>
          <p dir="ltr">
            Für die Dauer einer nicht vertragsgemäßen Erbringung der Reise kann
            der Kunde eine entsprechende Herabsetzung des Reisepreises verlangen
            (Minderung). Der Reisepreis ist in dem Verhältnis herabzusetzen, in
            welchem zur Zeit des Verkaufs der Wert der Reise in mangelfreiem
            Zustand zu dem wirklichen Wert gestanden haben würde. Die Minderung
            tritt nicht ein, soweit es der Kunde schuldhaft unterlässt, den
            Mangel anzuzeigen. Wird die Reise infolge eines Mangels erheblich
            beeinträchtigt und leistet Elinor innerhalb einer angemessenen Frist
            keine Abhilfe, so kann der Kunde im Rahmen der gesetzlichen
            Bestimmungen den Reisevertrag- inseinem eigenenInteresseund
            ausBeweissicherungsgründen zweckmäßig durch schriftliche Erklärung -
            kündigen. Dasselbe gilt, wenn dem Kunden die Reise infolge eines
            Mangels aus wichtigem, für Elinor erkennbaren Grund nicht zuzumuten
            ist. Der Bestimmung einer Frist für die Abhilfe bedarf es nur dann
            nicht, wenn Abhilfe unmöglich ist oder von Elinor verweigert wird
            oder wenn die sofortige Kündigung des Vertrages durch ein besonderes
            Interesse des Kunden gerechtfertigt wird.
          </p>
          <p dir="ltr">
            Der Kunde schuldet Elinor den auf die in Anspruch genommenen
            Leistungen entfallenen Teil des Reisepreises, sofern diese
            Leistungen für ihn von Interesse waren. Der Kunde kann unbeschadet
            der Minderung oder der Kündigung Schadensersatz wegen Nichterfüllung
            verlangen, es sei denn, der Mangel der Reise beruht auf einem
            Umstand, den Elinor nicht zu vertreten hat.
          </p>
          <p className="copyright__item-title">
            9. Pass-, Visa und Gesundheitsvorschriften
          </p>
          <p dir="ltr">
            Der Kunde ist für die Einhaltung aller für die Durchführbarkeit der
            Reise wichtigen Vorschriften verantwortlich. Alle Nachteile,
            insbesondere Zahlung von Rücktrittskosten, die aus der
            Nichtbefolgung dieser Vorschriften erwachsen, gehen zu Lasten des
            Kunden, ausgenommen, wenn sie durch eine schuldhafte Falsch- oder
            Nichtinformation von Elinor bedingt sind. Elinor haftet nicht für
            die rechtzeitige Erteilung und den Zugang notwendiger Visa durch die
            jeweilige diplomatische Vertretung, wenn der Kunde ihn mit der
            Besorgung beauftragt hat, es sei denn, dass Elinor eigene Pflichten
            schuldhaft verletzt hat. 9.1 Reiseunterlagen Der Kunde hat Elinor zu
            informieren, wenn er die erforderlichen Reiseunterlagen (z.B.
            Flugschein, Hotelgutschein) nicht innerhalb der von Elinor
            mitgeteilten Frist erhält. 9.2. Mängelanzeige/Abhilfeverlangen Der
            Kunde ist verpflichtet, bei auftretenden Leistungsstörungen im
            Rahmen der gesetzlichen Bestimmungen mitzuwirken, eventuelle Schäden
            zu vermeiden oder gering zu halten. Eventuelle Beanstandungen sind
            unverzüglich gegenüber Elinor anzuzeigen. Sollte die Reise nicht
            vertragskonform erbracht werden, oder sollten Mängel der Reise
            festgestellt werden, so kann der Kunde Abhilfe verlangen. In diesem
            Fall ist das Abhilfeverlangen oder die Mängelanzeige telefonisch,
            per Fax oder per Emailschreiben zu richten an:
          </p>
          <p dir="ltr">Elinor GmbH</p>
          <p dir="ltr">Kruppstrasse 100</p>
          <p dir="ltr">40227 Düsseldorf</p>
          <p dir="ltr">Fax.: +49-0-211-92417732</p>
          <p dir="ltr">
            Email:
            <a href="mailto:incoming@elinortravel.com">
              incoming@elinortravel.com
            </a>
          </p>
          <p dir="ltr">
            Unterlässt es der Kunde schuldhaft, einen Mangel anzuzeigen, so
            tritt ein Anspruch auf Minderung nicht ein
          </p>
          <p dir="ltr">9.3 Fristsetzungen vor Kündigung</p>
          <p dir="ltr">
            Will ein Kunde den Reisevertrag wegen eines Reisemangels der in §
            651 c BGB bezeichneten Art nach § 651 e BGB oder aus wichtigem,
            Elinor erkennbaren Grund wegen Unzumutbarkeit kündigen, hat er
            Elinor zuvor eine angemessene Frist zur Abhilfeleistung zu setzen.
            Dies gilt nur dann nicht, wenn Abhilfe unmöglich ist oder von Elinor
            verweigert wird oder wenn die sofortige Kündigung des Vertrages
            durch ein besonderes, Elinor erkennbares Interesse des Kunden/ den
            gerechtfertigt wird. 9.4 Gepäckbeschädigung und Gepäckverspätung
            Schäden oder Zustellverzögerungen bei Flugreisen empfiehlt der
            Veranstalter dringend unverzüglich an Ort und Stelle mittels
            Schadensanzeige (P.I.R.) der zuständigen Fluggesellschaft
            anzuzeigen. Fluggesellschaften lehnen in der Regel Erstattungen ab,
            wenn die Schadensanzeige nicht ausgefüllt worden ist. Die
            Schadensanzeige ist bei Gepäckbeschädigung binnen 7 Tagen und bei
            Verspätung innerhalb 21 Tagen nach Aushändigung, zu erstatten. Im
            Übrigen ist der Verlust, die Beschädigung oder die Fehlleitung von
            Reisegepäck dem Vertreter von Elinor bzw. dem Elinor anzuzeigen.
          </p>
          <p className="copyright__item-title">
            10. Geltendmachung von Ansprüchen
          </p>
          <p dir="ltr">
            Ansprüche nach den §§ 651 c bis f BGB hat der Kunde/ Reisende
            spätestens innerhalb eines Monats nach dem vertraglich
            vorgeschlagenen Zeitpunkt der Beendigung der Reise geltend zu
            machen. Die Frist beginnt mit dem Tag, der dem Tag des vertraglichen
            Reiseendes folgt. Die Geltendmachung kann fristwahrend gegenüber
            Elinor unter der vorstehend angegebenen Anschrift erfolgen.
          </p>
          <p dir="ltr">10.1. Verjährung</p>
          <p dir="ltr">
            Ansprüche des Kunden nach den §§ 651 c bis f BGB aus der Verletzung
            des Lebens, des Körpers oder der Gesundheit die auf einer
            vorsätzlichen oder fahrlässigen Pflichtverletzung von Elinor oder
            eines gesetzlichen Vertreters oder Erfüllungsgehilfen von Elinor
            beruht, verjähren in zwei Jahren. Dies gilt auch für Ansprüche auf
            den Ersatz sonstiger Schäden, die auf einer vorsätzlichen oder grob
            fahrlässigen Pflichtverletzung von Elinor oder eines gesetzlichen
            Vertreters oder Erfüllungsgehilfen von Elinor beruhen. Alle übrigen
            Ansprüche nach den §§ 651 c bis f BGB verjähren in einem Jahr. Die
            Verjährung beginnt mit dem Tag, der dem Tag des vertraglichen
            Reiseendes folgt.
          </p>
          <p className="copyright__item-title">
            11. Nutzung der Internetpräsenz
          </p>
          <p dir="ltr">
            Auf der Internetpräsenz www.elinortravel.de sowie den dazugehörenden
            Subdomains befinden sich Links zu anderen Seiten im Internet. Elinor
            betont ausdrücklich, dass sie keinen Einfluss auf die Gestaltung und
            den Inhalt der Seiten hat, auf die verlinkt wird. Elinor übernimmt
            daher keine Gewähr für die Aktualität, Richtigkeit, Vollständigkeit
            oder Qualität der dort bereitgestellten Informationen und
            distanziert sich hiermit ausdrücklich von allen Inhalten dieser
            Seiten. Diese Erklärung gilt für alle bei www.elinortravel.de
            enthaltenen Links zu externen Seiten und alle Inhalte dieser Seiten,
            zu denen diese Links führen.
          </p>
          <p className="copyright__item-title">12. Allgemeines</p>
          <p dir="ltr">
            Die Unwirksamkeit einzelner Bestimmungen des Reisevertrages hat
            nicht die Unwirksamkeit des gesamten Reisevertrages zur Folge. Das
            gleiche gilt für die vorliegenden Reisebedingungen.
          </p>
          <p dir="ltr">Stand August 2020</p>
          <p dir="ltr">Verantwortlich:</p>
          <p dir="ltr">
            Angaben gemäß § 5 TMG:
            <br />
          </p>
          <p dir="ltr">Elinor Travel,</p>
          <p dir="ltr">eine Marke der Elinor GmbH</p>
          <p dir="ltr">Kruppstrasse 100</p>
          <p dir="ltr">40227 Düsseldorf</p>
          <br />
          <p className="copyright__item-title">Kontakt:</p>
          <p dir="ltr">Telefon: 0211-93071112</p>
          <p dir="ltr">
            E-Mail:
            <a href="mailto:incoming@elinortravel.com">
              incoming@elinortravel.com
            </a>
          </p>
          <br />
          <p className="copyright__item-title">Registereintrag:</p>
          <p dir="ltr">
            Eintragung im Handelsregister.
            <br />
            Registergericht: Amtsgericht Düsseldorf
            <br />
            Registernummer: HRB 74491
          </p>
          <p className="copyright__item-title">Umsatzsteuer-ID:</p>
          <p dir="ltr">
            Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
            <br />
            DE 299480142
          </p>
          <p className="copyright__item-title">
            Quellenangaben für die verwendeten Bilder und Grafiken:
          </p>
          <p dir="ltr">
            istock.com
            <br />
            Düsseldorf Tourismus
            <br />
            Visit Berlin.de
            <br />
            Visit München.de
            <br />
            Capela Breidenbacher Hof
            <br />
            Eigentum der Firma Elinor GmbH
          </p>
          <p className="copyright__item-title">Haftungsausschluss:</p>
          <p dir="ltr">Quelle: Impressumgenerator, http://www.e-recht24.de</p>
          <br />
          <p className="copyright__item-title">Haftung für Inhalte</p>
          Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
          die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
          jedoch keine Gewähr übernehmen. Als Dienstanbieter sind wir gemäß § 7
          Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
          Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
          Dienstanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
          <br />
          <br />
          <p className="copyright__item-title">Haftung für Links</p>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
          <br />
          <br />
          <p className="copyright__item-title">Urheberrecht</p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
          <br />
          <br />
          <p className="copyright__item-title"> Datenschutz und DSGVO</p>
          Die Nutzung unserer Webseite ist in der Regel ohne Angabe
          personenbezogener Daten möglich. Soweit auf unseren Seiten
          personenbezogene Daten (beispielsweise Name, Anschrift oder
          Email-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
          auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
          Zustimmung nicht an Dritte weitergegeben.
          <br />
          <br />
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.
          <br />
          <br />
          Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
          Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
          angeforderter Werbung und Informationsmaterialien wird hiermit
          ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
          ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
          von Werbeinformationen, etwa durch Spam-Mails, vor.
          <br />
          Hier gelangen Sie zu unserer Seite: Datenschutz
          <br />
          <br />
          <p className="copyright__item-title">
            Datenschutzerklärung für die Nutzung von Facebook-Plug-Ins
            (Like-Button)
          </p>
          Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, 1601
          South California Avenue, Palo Alto, CA 94304, USA integriert. Die
          Facebook-Plugins erkennen Sie an dem Facebook-Logo oder dem
          "Like-Button" ("Gefällt mir") auf unserer Seite. Eine Übersicht über
          die Facebook-Plugins finden Sie hier:
          http://developers.facebook.com/docs/plugins/.
          <br />
          Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte
          Verbindung zwischen Ihrem Browser und dem Facebook-Server hergestellt.
          Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse
          unsere Seite besucht haben. Wenn Sie den Facebook "Like-Button"
          anklicken während Sie in Ihrem Facebook-Account eingeloggt sind,
          können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil
          verlinken. Dadurch kann Facebook den Besuch unserer Seiten Ihrem
          Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter
          der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie
          deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu
          finden Sie in der Datenschutzerklärung von facebook unter
          http://de-de.facebook.com/policy.php
          <br />
          <br />
          Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem
          Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus Ihrem
          Facebook-Benutzerkonto aus.
          <br />
          Weitere Informationen zum Datenschutz bei Facebook finden Sie hier:
          <br />
          https://www.facebook.com/privacy/explanation
          <br />
          <br />
          <p dir="ltr">
            <p className="copyright__item-title">
              Online Streitbeilegungsplattform
            </p>
            Die Europäische Kommission stellt eine Plattform für die
            außergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, die
            unter
            <a href="https://www.flyandaway.de/">
              www.ec.europa.eu/consumers/odr
            </a>
            aufrufbar ist. Unsere E-Mail-Adresse finden Sie weiter oben. Wir
            sind weder verpflichtet noch bereit, an dem
            Streitschlichtungsverfahren teilzunehmen.
            <br />
            Quellen: Disclaimer eRecht24, Facebook Datenschutzerklärung
            <br />
          </p>
          <br />
        </div>
      </div>
    </div>
  );
};

export default Copyright;
