import React from 'react';
import './privacy.scss';
import TitleItem from '../../TitleItem/TitleItem';

const Privacy = () => {
  return (
    <div className="privacy">
      <div className="privacy__container">
        <TitleItem title="Privacy" />
      </div>
    </div>
  );
};

export default Privacy;
