import React from "react";
import "./contact.scss";
import EmailIcon from "@material-ui/icons/Email";
import CallIcon from "@material-ui/icons/Call";
import BusinessIcon from "@material-ui/icons/Business";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import LinkIcon from "@material-ui/icons/Link";
import MyMapComponent from "../../Map/GoogleApiWrapper";
import PageBanner from "../../PageBanner/PageBanner";
import contactImage from "../../../assets/images/pages/contact.jpg";

const Contact = () => {
  return (
    <div className="contact">
      <div className="about__banner">
        <PageBanner image={contactImage} title="contact us"></PageBanner>
      </div>
      <div className="contact__container">
        <div className="contact__section-container">
          <div className="contact__section">
            <div className="contact__description">
              <div className="contact__description-item">
                <div className="contact__description-icon">
                  <BusinessIcon />
                </div>
                <div className="contact__description-label">
                  Elinor Travel Düsseldorf
                </div>
                <div className="contact__description-label">
                  Kruppstraße 100
                </div>
                <div className="contact__description-label">
                  40227 Düsseldorf
                </div>
              </div>
              <div className="contact__description-item">
                <div className="contact__description-icon">
                  <CallIcon />
                </div>
                <div className="contact__description-label">
                  +49(0) 211 93071112
                </div>
              </div>
              <div className="contact__description-item">
                <div className="contact__description-icon">
                  <SmartphoneIcon />
                </div>
                <div className="contact__description-label">
                  +49(0) 152 53994914
                </div>
              </div>
              <div className="contact__description-item">
                <div className="contact__description-icon">
                  <EmailIcon />
                </div>
                <div className="contact__description-label">
                  incoming@elinortravel.com
                </div>
              </div>
              <div className="contact__description-item">
                <div className="contact__description-icon">
                  <LinkIcon />
                </div>
                <div className="contact__description-label">
                  www.elinortravel.com
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact__map-container">
        <MyMapComponent></MyMapComponent>
      </div>
    </div>
  );
};

export default Contact;
