import React from 'react';
import './work-with-us.scss';
import servicesImage from '../../../assets/images/pages/services.jpg';
import PageBanner from '../../PageBanner/PageBanner';

const WorkWithUs = () => {
  return (
    <div className="work-with-us">
      <div className="about__banner">
        <PageBanner image={servicesImage} title="Why Us"></PageBanner>
      </div>
      <div className="work-with-us__container">
        <div className="work-with-us__section">
          <div className="work-with-us__description">
            <div className="work-with-us__item">
              <div className="work-with-us__item-title">
                Efficient and friendly approach
              </div>
              <div className="work-with-us__item-description">
                Our team of experts takes pride in their thoughtful approach to
                help you in finding the best travel solution for your client as
                per the desired travel service alongside with providing you with
                optimal alternatives.
              </div>
            </div>
            <div className="work-with-us__item">
              <div className="work-with-us__item-title">Business Partners</div>
              <div className="work-with-us__item-description">
                After several years in business and a lifetime of living and
                travelling experience, we have developed a deep knowledge and
                formed relationships with the most impressive experts in the
                tourism field in Germany and Europe. Moreover our partnerships
                with the biggest & most reputable travel agencies in the GCC
                countries have gained us a prominent place in the incoming
                business. We are proud with our strategic business partnership
                with more than 150 travel agencies in GCC countries – mainly
                Saudi Arabia, Kuwait, United Arab Emirates, and Qatar.
              </div>
            </div>
            <div className="work-with-us__item">
              <div className="work-with-us__item-title">
                Flexible and hassle free
              </div>
              <div className="work-with-us__item-description">
                We’re here to take the stress out of the travel planning
                process. Your client can be as involved as he want in designing
                his travel package, and he is always in control. Everything is
                tailored to your exact needs and tastes, and we will keep
                tweaking and adapting until it feels just right.
              </div>
            </div>
            <div className="work-with-us__item">
              <div className="work-with-us__item-title">Value and prices</div>
              <div className="work-with-us__item-description">
                We ensure to provide the best rates for every travel service
                offered and for every hotel booking as we make our money by
                negotiating contracted rates with the hotels, expert guides,
                activity providers we work with. We monitor our costs very
                closely.
              </div>
            </div>
            <div className="work-with-us__item">
              <div className="work-with-us__item-title">
                Quality and service
              </div>
              <div className="work-with-us__item-description">
                At Elinor GmbH Travel, quality and service go hand-in-hand. We
                do our utmost to ensure that these two hallmarks are met at
                every step of any booking. To help achieve these core values, we
                closely monitor client satisfaction and are consistently seeking
                new ways to exceed our clients’ expectations.
              </div>
            </div>
            <div className="work-with-us__item">
              <div className="work-with-us__item-title">Local expertise </div>
              <div className="work-with-us__item-description">
                It is vital to us that your client will have a memorable and
                authentic experience with our travel services and packages. In
                line with the expectations we have for our staff, we select only
                local suppliers that are friendly, reputable and have in-depth
                local knowledge so you can rest assure that your client is
                having a life time experience.
              </div>
            </div>
            <div className="work-with-us__item">
              <div className="work-with-us__item-title">
                You’re in good hands
              </div>
              <div className="work-with-us__item-description">
                Help is never out of reach. We have a team of dedicated &
                experienced Tour Managers to accompany you on every booked
                service. Moreover, there is always a staff member on call 24/7
                to handle any unforeseen situations.
              </div>
            </div>
            <div className="work-with-us__item">
              <div className="work-with-us__item-title">Legitimacy</div>
              <div className="work-with-us__item-description">
                Elinor GmbH Travel is an insured and fully licensed travel
                agency approved by the local authorities. Furthermore, we do
                work directly & closely with Düsseldorf Tourism Board. And as members of IATA - Travel Industry Designator Service, we are held up
                to a high level of standards and practices.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkWithUs;
