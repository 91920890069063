import React from 'react';
import './terms.scss';
import TitleItem from '../../TitleItem/TitleItem';

const Terms = () => {
  return (
    <div className="terms">
      <div className="terms__container">
        <TitleItem title="Terms & Conditions" />
      </div>
    </div>
  );
};

export default Terms;
