import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './banner.scss';
export interface BannerProps {
  banners: BannerImage[];
}

export interface BannerImage {
  source: string;
  name: string;
  link: string;
}

const Banner = (props: BannerProps) => {
  const images = props.banners.map((banner: BannerImage, id) => {
    if (banner.link) {
      return (
        <a className="banner__link" key={id} href={banner.link}>
          <div className="banner__image-container">
            <img
              className="banner__image"
              src={banner.source}
              alt={banner.name}
            />
          </div>
        </a>
      );
    } else {
      return (
        <div key={id} className="banner__image-container">
          <img
            className="banner__image"
            src={banner.source}
            alt={banner.name}
          />
        </div>
      );
    }
  });

  return (
    <div className="banner">
      <div className="banner__text-container">
        <div className="banner__text">Your gate to Germany</div>
      </div>
      <div className="banner__carousel">
        <Carousel
          showStatus={false}
          showThumbs={false}
          infiniteLoop
          transitionTime={1000}
          interval={5000}
          useKeyboardArrows
          autoPlay
          className="banner"
        >
          {images}
        </Carousel>
      </div>
    </div>
  );
};

export default Banner;
