import React, { useState, useRef, MutableRefObject } from 'react';
import CategoryItem from '../CategoryItem/CategoryItem';
import './categories.scss';
import { Category } from '../../types/category';
import 'react-multi-carousel/lib/styles.css';
import beauty from '../../assets/images/services/beauty.jpg';
import carRental from '../../assets/images/services/car_rental.png';
import checkup from '../../assets/images/services/checkup.jpg';
import hotel from '../../assets/images/services/hotel.jpeg';
import customized from '../../assets/images/services/customized.jpg';
import { isMobile } from 'react-device-detect';

const Categories = () => {
  const defaultCategories: Category[] = [
    {
      name: 'Hotels',
      image: hotel,
      isSelected: false,
      description:
        'Meeting & exceeding your expectations is our success criteria. Wether you are planning a family vacation, a business trip, a liveness and wellness trip or you are a honeymooner, Elinor Travel provides you with the best hotels variety that suits your needs.'
    },
    {
      name: 'Excursions',
      image: carRental,
      isSelected: false,
      description:
        'Our ready-made as well as our customized activity programs are designed to ensure that you make the most out of your trip and to explore Germany at its best.'
    },
    {
      name: 'Logistics',
      image: checkup,
      isSelected: false,
      description:
        'Our Services pool is available to cater your needs. Wether a Meet and Assist, Travel Guide, Private Transfers, English and Arabic speaking drivers is required, our services are dedicated to ensure you have a stress free vacation.',
      invertImage: true
    },
    {
      name: 'Medical',
      image: beauty,
      isSelected: false,
      description:
        'Because your health is priority, Elinor Travel provides you with the best Medical Centers in Germany that provides Medical checkup, Diet programs, and other specialized medical services.'
    },
    {
      name: 'VIP',
      image: customized,
      isSelected: false,
      description:
        'Luxury is a state of life, and Elinor Travel is up for it. Private Jets, Airport VIP Meet & Assist, Limousine Transfers. We take care of the finest detail. Your luxury is our Excellence.'
    }
  ];
  const [categories, setCategories] = useState(defaultCategories);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const categoryDescriptionRef = useRef(null);
  const categoriesRef = useRef(null);

  const changeSelectedCategory = (category: Category) => {
    const updatedCategory = category;
    updatedCategory.isSelected = !updatedCategory.isSelected;
    if (updatedCategory.isSelected) {
      if (isMobile) {
        scrollToReference(categoryDescriptionRef, 50);
      } else {
        scrollToReference(categoriesRef, 100);
      }

    }
    const updatedCategories = categories.map(categoryItem => {
      if (categoryItem.name !== category.name) {
        categoryItem.isSelected = false;
      }
      return categoryItem;
    });
    setCategories(updatedCategories);
    setSelectedCategory(updatedCategory);
  };

  const scrollToReference = (reference: MutableRefObject<any>, margin: number) => {
    window.scrollTo(0, reference.current.offsetTop - margin);
  };

  const getClassName = (isInverted: boolean | undefined) => {
    const className = 'categories__image';
    return isInverted ? `${className} ${className}_is-inverted` : className;
  }

  let categoryContentClassName = 'categories__category-content';
  if (selectedCategory.isSelected) {
    categoryContentClassName = 'categories__category-content';
  } else {
    categoryContentClassName =
      'categories__category-content categories__category-content_is-hidden';
  }
  const category = categories.map((category: Category, index) => (
    <div
      key={index}
      className="categories__category-item"
      onClick={() => {
        changeSelectedCategory(category);
      }}
    >
      <CategoryItem category={category} />
    </div>
  ));
  return (
    <div className="categories">
      <div className="categories__container">
        <div ref={categoriesRef} className="categories__category-items">{category}</div>
        <div ref={categoryDescriptionRef} className={categoryContentClassName}>
          <div className="categories__image-container">
            <img className={getClassName(selectedCategory.invertImage)} src={selectedCategory.image} />
          </div>
          <div className="categories__category-description">
            {selectedCategory.description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
