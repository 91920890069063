import React from 'react';
import './App.scss';
import AppContent from '../Partials/AppContent/AppContent';
import { AppProvider } from '../../shared/context/AppContext';
import { BrowserRouter as Router } from 'react-router-dom';

let App = () => {
  return (
    <AppProvider>
      <Router>
        <div className="app">
          <AppContent />
        </div>
      </Router>
    </AppProvider>
  );
};

export default App;
