import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Contact from '../Pages/Contact/Contact';
import Privacy from '../Pages/Privacy/Privacy';
import Terms from '../Pages/Terms/Terms';
import About from '../Pages/About/About';
import Home from '../App/Home/Home';
import WorkWithUs from '../Pages/WorkWithUs/WorkWithUs';
import Partners from '../Pages/Partners/Partners';
import Copyright from '../Pages/Copyright/Copyright';

const AppRouter = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/why-us">
          <WorkWithUs />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/partners">
          <Partners />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/copyright">
          <Copyright />
        </Route>
      </Switch>
    </div>
  );
};

export default AppRouter;
