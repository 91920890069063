import React, { useContext, useEffect, useState } from 'react';
import './header.scss';
import NavItem from '../../NavItem/NavItem';
import { NavLink } from 'react-router-dom';
import { PageLink, Pages } from '../../../shared/shared';
import logo from '../../../assets/images/logos/logo.gif';
import { AppContext } from '../../../shared/context/AppContext';
import Menu from '@material-ui/icons/Menu';
import Close from '@material-ui/icons/Close';

const headerLinks: Array<PageLink> = [
  { name: Pages.HOME, link: '/', exact: true },
  { name: Pages.ABOUT_US, link: '/about', exact: true },
  { name: Pages.WORK_WITH_US, link: '/why-us', exact: true },
  { name: Pages.PARTNERS, link: '/partners', exact: true },
  { name: Pages.CONTACT_US, link: '/contact', exact: true }
];

const headerNavItems = headerLinks.map((link, index) => (
  <div key={index} className="header__nav-item">
    <NavItem name={link.name} link={link.link} exact={link.exact} />
  </div>
));
const Header = () => {
  const appContext = useContext(AppContext);
  const [headerClassName, setHeaderClassName] = useState('header');
  const openMenu = () => {
    appContext.setIsMenuOpened(true);
  };

  const closeMenu = () => {
    appContext.setIsMenuOpened(false);
  };

  useEffect(() => {
    let headerClassName = 'header';
    let fixedModifier = '';
    let menuOpenedModifier = '';
    let transparentModifier = '';

    fixedModifier = appContext.isHeaderFixed ? 'header_is-fixed' : '';
    menuOpenedModifier = appContext.isMenuOpened ? 'header_is-menu-opened' : '';
    transparentModifier = appContext.isHeaderTransparent ? 'header_is-transparent' : '';

    setHeaderClassName(`${headerClassName} ${fixedModifier} ${menuOpenedModifier} ${transparentModifier}`);
  }, [appContext])

  return (
    <div className={headerClassName}>
      <div className="header__container">
        <div className="header__logo-container">
          <NavLink to="/" className="header__logo-link">
            <img className="header__logo" src={logo} alt="Elinor Travel" />
          </NavLink>
        </div>
        <div className="header__nav-menu" onClick={openMenu}>
          <Menu />
        </div>
        <div className="header__nav-close" onClick={closeMenu}>
          <Close />
        </div>
        <div className="header__nav-container">{headerNavItems}</div>
      </div>
    </div>
  );
};

export default Header;
