import React, { createContext, useState } from 'react';
import { AppContextInterface } from '../shared';
const defaultContext: AppContextInterface = {
  isHeaderTransparent: false,
  setIsHeaderTransparent: () => {},
  isHeaderFixed: false,
  setIsHeaderFixed: () => { },
  isMenuOpened: false,
  setIsMenuOpened: () => { },
};
export const AppContext = createContext(defaultContext);

export const AppProvider = ({ children }: any) => {
  let [isHeaderTransparent, setIsHeaderTransparent] = useState(false);
  let [isHeaderFixed, setIsHeaderFixed] = useState(false);
  let [isMenuOpened, setIsMenuOpened] = useState(false);
  const appContext: AppContextInterface = {
    isHeaderTransparent,
    setIsHeaderTransparent,
    isHeaderFixed,
    setIsHeaderFixed,
    isMenuOpened,
    setIsMenuOpened
  };
  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
};
