import React from 'react';
import './page-banner.scss';

export interface PageBannerProps {
  image: string;
  title: string;
}

const PageBanner = (props: PageBannerProps) => {
  return (
    <div className="page-banner">
      <div className="page-banner__label-container">
        <div className="page-banner__label">{props.title}</div>
      </div>
      <div className="page-banner__image-container">
        <img
          src={props.image}
          alt={props.title}
          className="page-banner__image"
        />
      </div>
    </div>
  );
};

export default PageBanner;
