import React from 'react';
import './title-item.scss';

export interface TitleProps {
  title: string;
}

const TitleItem = (props: TitleProps) => {
  return (
    <div className="title-item">
      <div className="title-item__container">
        <div className="title-item__title">{props.title}</div>
      </div>
    </div>
  );
};

export default TitleItem;
